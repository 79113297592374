import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <h1>Cette page est introuvable.</h1>
    <p>
      La page que vous cherchez n'existe pas (ou plus).
    </p>
    <p>
      Vous pouvez retourner sur :
      <ul>
        <li><Link to="/espaces-d-activites">lister les espaces d'activités</Link>,</li>
        <li><Link to="/explorer">Explorer/Filtrer les espaces d'activités</Link>.</li>
      </ul>
    </p>
  </Layout>
);

export default NotFoundPage;
